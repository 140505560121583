<template>
  <div>
  <div v-if="showPopup" class='popup-container' @click="closePopup">
      <div class='popup'>
        <div class='popup-content'>
          <button type="button" class="close" aria-label="Close" @click="closePopup">
            <span aria-hidden="true" class="pull-right">
              <img src="@/assets/images/Icon-Close.svg" alt="" class="close--icon">
            </span>
          </button>
          <div class='popup-title'>
            <!--{{information[selectedIndex].title}}-->
            Hilfe zur Veranstaltung
          </div>
          <p class='popup-body pre-formatted'>
            <!--{{information[selectedIndex].text}}-->
          Falls Sie Probleme in der Nutzung der Plattform haben, prüfen Sie bitte vorab die folgenden Anforderungen an Ihr Gerät:
          </p>
          <ul class='popup-body pre-formatted' type="square">
            <li>Sie nutzen die aktuelle Version des <a href="https://www.google.com/intl/de_de/chrome/" target="_blank">Google Chrome Browsers</a> oder <a href="https://www.mozilla.org/de/firefox/new/" target="_blank">Mozilla Firefox</a></li>
            <li>Sie verfügen über eine ausreichend schnelle Internet-Verbindung für Videostreaming im Browser (minimum 25mbit/s empfohlen) <a href="https://speedtest.chip.de/" target="_blank">Hier prüfen</a></li>
            <li>Sie nutzen ein modernes Betriebssystem wie Windows 10 oder macOS Catalina und ausreichendem Prozessor (z.B. Intel i5 Quad-Core mit 2,2 GhZ)</li>
            <li>Falls Sie die Plattform vom Tablet aus nutzen, ist dies minimum Baujahr 2018 und auf Leistungs-Niveau eines iPad</li>
            <li>Falls Sie die Plattform vom Tablet aus nutzen, beachten Sie, dass Sie für Video-Chats die kostenfreie APP "Jitsi meet" installieren müssen <a href="https://jitsi.org/downloads/" target="_blank">Hier für Tablet laden</a></li>
            <li>Ihr Bildschirm hat eine Auflösung von mindestens 1280x720 Pixel</li>
            <li>Für die Videochat-Funktion benötigen Sie eine funktionstüchtige Webcam und Mikrofon an Ihrem Gerät</li>
            <li>Falls im Video-Chat Ihre Kamera oder Ihr Mikrofon nicht erkannt wird, drücken Sie auf die kleinen Pfeile neben dem Icon "Kamera" oder "Mikrofon" um eines aus der Liste zu wählen</li>
            <li>Falls Sie Probleme beim Login haben, prüfen Sie, dass Sie Ihren Account nicht versehentlich in den Einstellungen deaktiviert haben und registrieren sich ggfs. mit einer neuen eMail Adresse erneut</li>
          </ul>
          <div class="row mt-5">
            <div class="col-md-3"></div>
            <div class="col-md-6">
            <video controls="controls" playsinline width="100%" class="video--full" src="https://app01.b-rex.de/api/data/exhibit/04d61010-b2d4-11ea-956b-7712b95fc494/20200619_HaefeleHolzhandwerk_Tutorial_v1.mp4"></video>
            </div>
            <div class="col-md-3"></div>
          </div>
          <p class='popup-body pre-formatted'>Falls Ihnen die oben genannten Tipps nicht weiter helfen, wenden Sie sich bitte via eMail an <b> {{ $t('views.help_mail') }} </b> </p>
        </div>
      </div>
  </div>
  <footer class="footer page-footer footer__haefele">
    <div class="h-100 footer-content">
      <div class="d-flex flex-row align-items-center justify-content-between">
        <div class=" white-text ml-sm-0 ml-md-4">
          <span class="footer-text">
            &copy; Häfele GmbH &amp; Co.  KG
          </span>
        </div>
        <div class='d-flex justify-content-end flex-grow-1'>
            <span class="normal-txt privacy ml-4" @click="openPopup(0)">{{ $t('views.help_title') }}</span>
            <span class="footer-text privacy ml-4" @click="toData()">{{ $t('views.privacy_title') }}</span>
            <span class="footer-text privacy ml-4" @click="toImpressum()">{{ $t('views.imprint_title') }}</span>
        </div>
        <div id="temp" class="contact-us text-center mr-sm-0 mr-md-4">
          <a href="https://twitter.com/haefele_de" target="_blank"><span><img class="contact-icon fab icon__instagram ml-4" src="@/assets/Icons/Icons/icon-twitter.svg" alt=""></span></a>
          <a href="https://www.facebook.com/Haefele.de" target="_blank"><span><img class="contact-icon fab icon__instagram ml-4" src="@/assets/Icons/Icons/icon-facebook.svg" alt=""></span></a>
          <a href="https://www.instagram.com/haefele_de/" target="_blank"><span><img class="contact-icon fab icon__instagram ml-4" src="@/assets/Icons/Icons/icon-insta.svg" alt=""></span></a>
          <a href="https://www.youtube.com/user/HaefeleDE" target="_blank"><span><img class="contact-icon fab icon__instagram ml-4" src="@/assets/Icons/Icons/icon-yt.svg" alt=""></span></a>
        </div>
      </div>
    </div>
  </footer>
  </div>
</template>
<script>
export default {
  props: {
    showPopup: Boolean,
    closePopup: Function,
    openPopup: Function,
    selectedIndex: Number
  },
  data() {
    return {
      information: [{
        title: this.$t('views.imprint_title'),
        text: this.$t('views.imprint_body')
      },
      {
        title: this.$t('views.privacy_title'),
        text: this.$t('views.privacy_body')
      }
      ]
    };
  },
  methods: {
    toData(){
      let routeData = this.$router.resolve({name: 'datenschutzerklaerung'});
      window.open(routeData.href, '_blank');  
    },

    toImpressum(){
      let routeImpressum = this.$router.resolve({name: 'impressum'});
      window.open( routeImpressum.href, '_blank');      
    }
  }
};
</script>

<style lang='scss' scoped>

  .footer{
    &__haefele{
        background-image: linear-gradient(92deg, rgba(210, 10, 40, 1), rgba(140, 35, 50, 1));
        padding: 13px 0 14px 5px;
    }
  }
  .popup-container {
    min-height: 100%;
    width: 100%;
    position: fixed;
    z-index: 110;
    top: 50%;
    left: 50%;
    overflow-y: scroll;
    scrollbar-color:$scrollbarHaefele;
    scrollbar-width: thin;
    transform: translate(-50%, -50%);
    padding-top: 100px;
    padding-bottom: 150px !important;
  }

  .pre-formatted {
    white-space: pre-line;
  }

  .icon__instagram{
    height: 15px !important;
    width: 15px !important;
    margin-top:-3px;
  }
  .popup {
    background-color: #000000;
    width: 70%;
    z-index: 110;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 !important;
    padding-bottom: 50px !important;
  }

  .popup-content {
    padding-left: 85px;
    padding-right: 85px;
    position: relative;
    overflow-x: scroll;
    height: 80vh;
  }

 .close {
  position: absolute;
  font-size: 20px;
  right: 50px;
  top: 50px;
  z-index: 112;
  color: white;
  opacity: 1 !important;
  font-weight: 100;
  transform: translate(-50%, -50%);

  &--icon{
    width: 14px;
  }
}

  .popup-title {
    letter-spacing: 1.2px;
    color: $primaryColor;
    font-size: 48px;
    font-weight: 300;
    padding-top: 45px;
  }

  .popup-body{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.49px;
    color: #FFFFFF;
    margin-top: 55px;
  }

  .footer {
    position: fixed;
    z-index: 111;
    width: 100%;
    min-height: 50px;
    justify-content: center;
    display: flex;
  }

  .privacy:hover {
    cursor: pointer;
  }

  .footer-content {
    width: 94%;
    margin: auto;
  }

  .contact-icon {
    font-size: 18px;
  }

  .contact-us{
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .footer {
    width: 100%;
    font-weight: lighter;
    font-size: 12px !important;
  }

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    .footer-text {
      font-size: 7px !important;
    }

    .footer-text strong {
      font-size: 10px;
    }

    .contact-icon {
      font-size: 10px;
    }

    .popup{
      width: 90%;
    }

    .popup-body{
      font-size: 10px;
      margin-top: 20px;
    }

    .popup-title{
      font-size: 20px;
      margin-top: 20px;
    }

    .popup-content{
      padding: 10px ;
    }

    .close{
      font-size: 15px;
    }
  }
</style>
