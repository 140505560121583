<template>
<div class ='haefele__header'>
      <Profile :user="user" :state="state" :closeProfile="closeProfile" class="profile" />
      
  <header id="header" :class="['header',headerOpacity]">
    <div
      class="d-flex flex-md-row justify-content-center mx-0 h-100 d-flex align-items-center all-header-container"
    >
      <span id="customerlogo" class="header-title white-text" @click="goHome()">
          <img src="~/assets/images/haefele_logo_1.svg" alt="">
      </span>
      <span id="backbtn" class="flex-even back" @click="goHome();closeProduct();" :class="backVisibility()">
        <i :class="['fas','fa-angle-left','mr-2']"></i>Zurück
      </span>
      <div class="header-container flex-even text-center"></div>
      <div
        :class="['flex-even','d-flex','flex-md-row','justify-content-end',searchVisibility(),`search-profile-container`]"
      >
        <form id="searchfield" class="d-flex flex-row m-0 p-0 text-center form-inline search" v-on:submit.prevent>
          <input
            class="form-control form-control-sm search-box"
            type="text"
            placeholder="  Suche"
            aria-label="Search"
            v-model="searchString"
            v-validate="'min:3'"
            data-vv-as="field"
            name="searchQuery"
            v-on:keyup.enter="search"
          />
          <span class="icon-holder m-0 p-0" @click="search">
            <i class="fas fa-search yellow p-0 m-0 search-icon" aria-hidden="true"></i>
          </span>
        </form>
      </div>
      <div v-if="productRegister()" class='header-register' @click="$router.push('/register')">
        {{$t('views.visit_fair_now')}}
      </div>
      <span
        aria-hidden="true"
        v-if="auth_state"
        class="profile-button white-text"
        @click="openProfile()"
      >
        <i class="profile-icon fas fa-user"></i>
      </span>
      <span aria-hidden="true" v-else class="profile-button white-text"></span>
    </div>
  </header>

  </div>
</template>

<script>
import Profile from 'components/Profile.vue';

export default {
  name: 'Header',
  components: {
    Profile
  },
  props: {
    opaqueHeader: Boolean,
    toggleBlur: Function,
    closeProduct: Function,
    closePopup: Function,
    closeChat: Function,
    closeNote: Function,
    user: Object
  },
  data() {
    return {
      auth_state: this.$store.getters.isAuthorized,
      headerOpacity: 'transparent-header',
      state: 'closed',
      searchString: this.$route.query.searchQuery || '',
      submitted: false
    };
  },
  methods: {
    closeProfile() {
      this.state = 'closed';
      this.toggleBlur();
    },
    openProfile() {
      this.state = 'open';
      this.toggleBlur();
    },
    searchVisibility() {
      if (!this.$store.getters.isAuthorized) {
        return 'hide';
      }
    },
    backVisibility() {
      if (!this.$store.getters.isAuthorized) {
        return 'hide';
      } else if (this.$route.name == 'menu') {
        return 'invisible';
      }
    },
    search(){
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          if(this.searchString)
            this.closeProduct();
          this.closePopup();
          this.closeChat();
          this.closeNote();
          this.$router.push('/search?searchQuery=' + this.searchString);
        }
      });
    },
    productRegister() {
      if (!this.$store.getters.isAuthorized && this.$route.name == 'ProductWindow') {
        return true;
      } else{
        return false;
      }
    },
    goHome() {
      if(
        this.$route.name == 'register' ||
        this.$route.name == 'login' ||
        this.$route.name == 'register' ||
        this.$route.name == 'registersuccess' ||
        this.$route.name == 'registerMail' ||
        this.$route.name == 'newPassword' ||
        this.$route.name == 'resetPassword' ||
        this.$route.name == 'landing'
      ) {
        console.log('route');
        this.$router.push('/');
      }
      else{
        this.$router.push('/menu');
      }
    }
  },
  mounted: function() {
    if (this.opaqueHeader) {
      this.headerOpacity = 'opaque-header';
    }
  },
  computed: {
    logged() {
      return this.$store.getters.isAuthorized;
    }
  },
  watch: {
    opaqueHeader() {
      if (this.opaqueHeader) {
        this.headerOpacity = 'opaque-header';
      } else {
        this.headerOpacity = 'transparent-header';
      }
    },
    logged(newState) {
      this.auth_state = newState;

      if (newState == false && this.state == 'open') {
        this.closeProfile();
      }
    }
  }
};
</script>

<style lang='scss' scoped>

.haefele__header{

  z-index: 999 !important;
}
  i.search-icon:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  i.profile-icon:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .all-header-container {
    position: relative;
  }
  
  .search-profile-container {
    position: relative;
  }

  .header {
    height: 70px;
    z-index: 99;
    width: 100%;
    padding-right: 2.8rem;
    padding-left: 1rem;
  }

  .search {
    width: 290px;
    margin-right: 10% !important;
    color: #ffffff99;
  }

  .search-box {
    background-color: rgba(0, 0, 0, 0);
    border-color: #ffffff99;
    border-radius: 0px;
    height: 34px;
    padding: 5px;
    width: 88% !important;
    color: white;
    font-weight: 400;
  }

  .search-box:focus {
    border-color: white;
  }

  .icon-holder {
    background-color: white;
    height: 34px;
    width: 12%;
    position: relative;
  }

  .search-icon {
    color: black;
    margin: 0 !important;
    padding: 0 !important;
  }

  .icon:hover {
    cursor: pointer;
  }

  .error-mssg {
    font-size: 10px;
  }

  .transparent-header {
    background-color: rgba(80, 80, 80, 0.9);
  }

  .flex-even {
    flex: 1;
  }

  .opaque-header {
    background-color: $haefeleGrey;
  }

  .header-title {
    font-size: 35px;
    font-weight: bold;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: -3px;
    img{
      height:34px;
    }
    &:hover{
      cursor:pointer;
    }
  }

  .title-margin {
    margin-left: 35%;
  }

  .profile {
    z-index: 500 !important;
    position: fixed;
    height: 100vh;
    background-color: red !important;

  }

  .back {
    width: 10%;
    padding-left: 85px;
    color: white;
    font-size: 12px;
  }

  .back:hover {
    cursor: pointer;
  }

  .profile-button {
    margin-right: 50px;
    height: 100%;
    position: relative;
  }

  .profile-icon {
    font-size: 13px;
  }

  .profile-button:hover {
    cursor: pointer;
  }

  .hide {
    display: none !important;
  }

  .invisible {
    visibility: hidden;
  }
 
 .header-register{
    color: #F4F044;
    font-size: 15px;
    letter-spacing: 0.26px;
  }

  .header-register:hover{
    cursor: pointer;
  }

  @media screen and (max-device-width: 768px) {
    .back {
      display: none;
    }

    .search {
      max-width: 150px;
      margin-right: 20px;
    }

    .header-title {
      left: 20%;
    }
  }
</style>
