<template>
  <div class="m-0 p-0 h-100">
    <!-- new notes / new chat modal -->
    <mdb-modal
      frame
      style="background-color: transparent; pointer-events:none; touch-action:none; width: 20%; top: 40.5%;"
      position="top-left"
      direction="left"
      :show="modal"
      @close="modal = false"
    >
      <mdb-modal-body :class="['text-center',modalClass]">
        <i :class="modalIcon"></i>&nbsp;&nbsp;&nbsp;&nbsp;
        <span @click="goToChat();closeModal()">{{modalMessage}}</span>
      </mdb-modal-body>
    </mdb-modal>
    <!-- new ? modal -->
    <mdb-modal
      frame
      style="background-color: transparent; pointer-events:none; touch-action:none;"
      position="bottom"
      direction="top"
      :show="$store.state.modal"
      @close="$store.state.modal = false"
    >
      <mdb-modal-body :class="['text-center',$store.state.modalClass]">
        <i :class="$store.state.modalIcon"></i>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>{{$store.state.modalMessage}}</span>
      </mdb-modal-body>
    </mdb-modal>

    <Chat
      v-closable="{
    exclude: ['popup'],
    handler: 'onClose'
  }"
      v-if="showChat"
      :removeFromUnreplayed="removeFromUnreplayed"
      :unreplayed="unreplayed"
      :errorMessage="errorMessage"
      :chatMessage="chatMessage"
      :onlineList="onlineList"
      :user="user"
      :messageNumbers="messageNumbers"
      :selectedUserId="selectedUserId"
      :closeChat="closeChat"
    ></Chat>
    <Note
      v-closable="{
    exclude: ['popup'],
    handler: 'onClose'
  }"
      v-if="showNotes"
      :showNoteMessage="showNoteMessage"
      :setSelectedExhibit="setSelectedExhibit"
      :selectedExhibit="selectedExhibit"
      :closeNote="closeNote"
      :selectedNote="selectedNote"
      :setSelectedNote="setSelectedNote"
      :toggleShowProduct="toggleShowProduct"
      :setSelectedUser="setSelectedUser"
      :toggleShowChat="toggleShowChat"
    ></Note>
    <Product
      v-closable="{
    exclude: ['popup'],
    handler: 'onClose'
  }"
      ref="product"
      v-if="showProduct"
      :setSelectedExhibit="setSelectedExhibit"
      :inWindow="false"
      :productId="selectedExhibit.uuid"
      :setSelectedUser="setSelectedUser"
      :toggleShowChat="toggleShowChat"
      :setSelectedNote="setSelectedNote"
      :goToNotes="goToNotes"
      :closeProduct="closeProduct"
    />

    <div v-if="blurred" id="mask"></div>
    <div v-if="showSideButtons()" class="side-button-container">
      <div id="sidechat" class="icon-container row m-0 p-0" @click="goToChat()">
        <span :class="['side-icon',color(),chatClass] ">
          <img
            v-if="chatClass == 'selected'"
            src="@/assets/Icons/Icons/Icon-Chat-white.svg"
            class="icon-img"
            height="23.5"
            width="24.6"
          />
          <img
            v-else
            src="@/assets/Icons/Icons/Icon-Chat.svg"
            class="icon-img"
            height="23.5"
            width="24.6"
          />
        </span>
        <span v-if="unreplayed.length > 0" class="message-badge">{{unreplayed.length}}</span>
      </div>
      <div class="icon-container row m-0 p-0 mt-2" @click="clickNotes()">
        <span :class="['side-icon',color(),noteClass] ">
          <img
            v-if="noteClass == 'selected'"
            src="@/assets/Icons/Icons/Icon-Notes-white.svg"
            class="icon-img"
            height="22"
            width="22"
          />
          <img
            v-else
            src="@/assets/Icons/Icons/Icon-Notes.svg"
            class="icon-img"
            height="22"
            width="22"
          />
        </span>
      </div>
      <Footer
        :showPopup="showPopup"
        :openPopup="openPopup"
        :closePopup="closePopup"
        :selectedIndex="selectedIndex"
      />
    </div>
    <div id="bg" :class="background()"></div>
    <Header
      :user="user"
      :closeProduct="closeProduct"
      :closePopup="closePopup"
      :closeNote="closeNote"
      :closeChat="closeChat"
      :opaqueHeader="opaqueHeader()"
      :toggleBlur="toggleBlur"
    />
    <div class="inbox-container" id="chat-available" :class="{blurBg: $store.state.popup}">
      <router-view
        :showNoteMessage="showNoteMessage"
        :setSelectedExhibit="setSelectedExhibit"
        :selectedExhibit="selectedExhibit"
        :closeNote="closeNote"
        :selectedNote="selectedNote"
        :setSelectedNote="setSelectedNote"
        :toggleShowProduct="toggleShowProduct"
        :setSelectedUser="setSelectedUser"
        :toggleShowChat="toggleShowChat"
        :goToNotes="goToNotes"
        :removeFromUnreplayed="removeFromUnreplayed"
        :unreplayed="unreplayed"
        :errorMessage="errorMessage"
        :chatMessage="chatMessage"
        :onlineList="onlineList"
        :user="user"
        :closeProduct="closeProduct"
        :showProduct="showProduct"
        :selectedUserId="selectedUserId"
      ></router-view>
    </div>
    <Footer
      :showPopup="showPopup"
      :openPopup="openPopup"
      :closePopup="closePopup"
      :selectedIndex="selectedIndex"
    />
  </div>
</template>
<script>
import Header from 'components/shared/HeaderHaefele.vue';
import Footer from 'components/shared/FooterHaefele.vue';
import Chat from 'components/shared/Chat.vue';
import Product from 'components/shared/Product.vue';
import Note from 'views/note/Notes.vue';
import { mdbModal, mdbModalBody } from 'mdbvue';
let handleClickOutside;
export default {
  name: 'Home',
  directives: {
    closable: {
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)

        handleClickOutside = e => {
          e.stopPropagation();
          if (
            e.target.className === 'popup-container overlay' ||
            e.target.className === 'content'
          ) {
            vnode.context.showProduct = false;
            vnode.context.showNotes = false;
            vnode.context.showChat = false;
            vnode.context.chatClass = '';
            vnode.context.noteClass = '';
            vnode.context.closeStorePopup();
          }
        };
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
      },

      unbind() {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      }
    }
  },
  components: {
    Header,
    Footer,
    Chat,
    Note,
    Product,
    mdbModal,
    mdbModalBody
  },
  data() {
    return {
      // opaqueHeader: false,
      lastDistributorCallLength:0,
      handleClickOutside: 'null',
      blurred: false,
      showPopup: false,
      selectedIndex: 0,
      showChat: false,
      showNotes: false,
      chatClass: '',
      noteClass: '',
      selectedUserId: '',
      showProduct: false,
      user: {},
      onlineList: null,
      chatMessage: {},
      errorMessage: {},
      unreplayed: [],
      messageNumbers: 0,
      selectedNote: '',
      wsUri: 'wss://munis.b-rex.de/ws',
      inPlan: false,
      selectedExhibit: {},
      modal: false,
      modalMessage: '',
      modalIcon: '',
      modalClass: ''
    };
  },
  mounted() {
    this.checkNewDistributorCall = setInterval(async ()=>{
      if(this.$store.state.user.roles && (this.$store.state.user.roles.includes('exhibitor') || this.$store.state.user.roles.includes('organizer'))){
        let distributerCall = await this.$http.get('distributor-call');
        let users = distributerCall.data;
        let userIDs = users.map(user => user.customer);
        let filterDistributorUsers = this.$store.state.usersOnline.filter(user =>
          userIDs.includes(user.uuid)
        ); 
          
        let distributorCallLength = filterDistributorUsers.length;
        if(distributorCallLength>this.lastDistributorCallLength){
          this.$store.state.unreplyedDistrubitorCalls = distributorCallLength-this.lastDistributorCallLength;
          this.playSound();
        }
        
        this.lastDistributorCallLength = distributorCallLength;
      }
    },5000);
    

    // // MEMO: This function fixes people being offline after video chat, by refreshing the page if they are in the right views
    var vis = (()=> {
      var stateKey,
        eventKey,
        keys = {
          hidden: 'visibilitychange',
          webkitHidden: 'webkitvisibilitychange',
          mozHidden: 'mozvisibilitychange',
          msHidden: 'msvisibilitychange'
        };
      for (stateKey in keys) {
        if (stateKey in document) {
          eventKey = keys[stateKey];
          break;
        }
      }
      return function(c) {
        if (c) document.addEventListener(eventKey, c);
        return !document[stateKey];
      };
    })();

    vis(()=> {
      var lookatme = vis() ? 'Visible' : 'Not visible';
      console.log(lookatme);
      if (lookatme == 'Visible') {
        this.prepareChat();
      } else {
        if (window.websocket) {
          window.websocket.close();
          window.websocket = null;
        }
      }
    });

    if (this.autherized) {
      this.getUser();
    }
    if (this.$route.name == 'plan') {
      this.inPlan = true;
    } else {
      this.inPlan = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.checkNewDistributorCall);
    if (window.websocket) {
      window.websocket.close();
      window.websocket = null;
    }
    //this.removeEventListener(this.registerChange); This is currently bugged and dropps errors in the console!
  },
  methods: {
    playSound(){
      var audio = new Audio(require('@/assets/sounds/message.mp3'));
      audio.play();
    },
    async reopenWebsocket() {
      this.prepareListener();
      this.$store.commit('getActiveUsers');
    },
    closeStorePopup() {
      this.$store.state.popup = false;
    },
    showNoteMessage(success) {
      this.modal = true;
      if (success == true) {
        this.modalMessage = this.$t('views.note_saved');
        this.modalIcon = 'fas fa-check-circle';
        this.modalClass = 'green-text';
      } else {
        this.modalMessage = this.$t('views.note_error');
        this.modalIcon = 'fas fa-times-circle';
        this.modalClass = 'red-text';
      }
      setTimeout(
        function() {
          this.modal = false;
        }.bind(this),
        3000
      );
    },
    setSelectedExhibit(exhibit) {
      this.selectedExhibit = exhibit;
    },
    getUser() {
      this.$http
        .get('/user')
        .then(resp => {
          localStorage.setItem('user-roles', resp.data.roles);
          this.user = resp.data;
          this.$store.state.user = resp.data;
        })
        .catch(err => {
          localStorage.removeItem('user-roles');
          console.log(err);
        });
    },
    prepareChat() {
      if (window.websocket) {
        window.websocket.close();
        window.websocket = null;
      }
      if (this.user.uuid) {
        window.websocket = new WebSocket(this.wsUri);
        window.websocket.onopen = () => {
          window.websocket.send(
            JSON.stringify({
              wsMessageType: 'client-login',
              username: this.user.uuid
            })
          );
          this.prepareListener();
        };
      }
    },
    prepareListener() {
      window.websocket.onmessage = evt => {
        var resp = JSON.parse(evt.data);
        if (resp.message && resp.message === 'recipient is not online!') {
          this.$store.state.modalMessage =
            'Der User ist momentan leider nicht online. Gesendete Nachrichten werden nicht zugestellt.';
          this.$store.state.modalIcon = 'fas fa-close';
          this.$store.state.modalClass = 'red-text';
          this.$store.state.modal = true;
        }
        if (resp.wsMessageType == 'e2e') {
          if (resp.sender !== this.$store.state.user.uuid) {
            this.modalMessage = 'Neue Nachricht für Sie - hier antworten.';
            this.modalIcon = 'fas fa-envelope';
            this.modalClass = 'red-text';
            this.modal = true;
          }
          this.chatMessage = resp;
          this.unreplayed.push(resp.sender);
          this.messageNumbers = this.unreplayed.length;
          setTimeout(
            function() {
              this.modal = false;
              this.$store.state.modal = false;
            }.bind(this),
            5000
          );
        } else {
          this.errorMessage = resp;
        }
      };
    },
    closeModal() {
      this.modal = false;
      this.$store.state.modal = false;
    },
    closeProduct() {
      this.$store.state.popup = false;
      this.showProduct = false;
    },
    toggleShowProduct() {
      this.closeChat();
      this.closeNote();
      this.$store.state.popup = true;
      this.showProduct = true;
    },
    setSelectedUser(id) {
      this.selectedUserId = id;
    },
    setSelectedNote(note) {
      this.selectedNote = note;
    },
    toggleShowChat() {
      this.showChat = true;

      this.chatClass = 'selected';
      this.closeProduct();
      this.closeNote();
      this.$store.state.popup = true;
    },

    opaqueHeader() {
      if (this.$route.name == 'videochat' || this.$route.name == 'krpano') {
        return true;
      } else {
        return false;
      }
    },
    openPopup(index) {
      this.selectedIndex = index;
      this.showPopup = true;
      this.closeProduct();
    },
    closePopup() {
      this.$store.state.popup = false;
      this.showPopup = false;
    },
    background() {
      // if (
      //   this.$route.name == 'login' ||
      //   this.$route.name == 'register' ||
      //   this.$route.name == 'registersuccess' ||
      //   this.$route.name == 'registerMail' ||
      //   this.$route.name == 'newPassword' ||
      //   this.$route.name == 'resetPassword' ||
      //   this.$route.name == 'landing'
      // ) {
      //   return 'background-3';
      // }
      if (
        this.$route.name == 'plan' ||
        this.$route.name == 'krpano' ||
        this.$route.name == 'videochat'
      ) {
        return '';
      } else if (
        this.$route.name == 'menu' ||
        this.$route.name == 'deepdive' ||
        this.$route.name == 'deepdiveakademie' ||
        this.$route.name == 'deepdiveslido'
      ) {
        return 'background-2';
      } else {
        return 'background-3';
      }
    },
    toggleBlur() {
      this.blurred = !this.blurred;
    },
    goToChat() {
      this.setSelectedUser();
      if (
        localStorage.getItem('user-roles').includes('exhibitor') ||
        localStorage.getItem('user-roles').includes('organizer')
      ) {
        this.$router.push('/exhibitor');
        this.closeNote();
        this.closePopup();
        this.closeProduct();
      } else {
        this.toggleShowChat();
      }
      this.messageNumbers = 0;
    },
    closeChat() {
      this.$store.state.popup = false;
      this.showChat = false;
      this.chatClass = '';
    },

    clickNotes() {
      this.selectedExhibit = {};
      this.goToNotes();
    },

    goToNotes() {
      this.closePopup();
      this.closeProduct();
      this.closeChat();
      this.$store.state.popup = true;
      this.showNotes = true;
      this.noteClass = 'selected';
    },

    closeNote() {
      this.$store.state.popup = false;
      this.showNotes = false;
      // this.setSelectedExhibit({uuid: ''});
      this.noteClass = '';
    },

    showSideButtons() {
      if (
        !this.$store.getters.isAuthorized ||
        this.$route.name == 'ausstelleransicht' ||
        this.$route.name == 'videochat' ||
        this.$route.path.includes('search')
      ) {
        return false;
      } else {
        return true;
      }
    },
    color() {
      if (this.$route.name == 'plan') {
        return 'in-plan';
      } else {
        return 'in-home';
      }
    },
    isNoteSelected() {
      if (this.$route.name && this.$route.name.includes('note')) {
        return 'selected';
      }
    },
    removeFromUnreplayed(id) {
      this.unreplayed = this.unreplayed.filter(
        unreplyed_id => unreplyed_id !== id
      );
    },
    setAnonymous(anonymous) {
      this.user.anonymous = anonymous;
    }
  },
  watch: {
    user() {
      this.prepareChat();
    },
    autherized() {
      this.getUser();
    },
    '$route.name'(to) {
      if (to == 'plan') {
        this.inPlan = true;
      } else {
        this.inPlan = false;
      }

      if (to == 'login' || to == 'register') {
        this.messageNumbers = 0;
        this.unreplayed = [];
        this.closeNote();
        this.closeChat();
        this.closePopup();
        this.closeProduct();
      }
    }
  },
  computed: {
    autherized() {
      return this.$store.getters.isAuthorized;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    }
  }
};
</script>

<style scoped>
body,
html {
  height: 100%;
}

#bg {
  height: 100%;
  width: 100%;
  background-position: 1% 1%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  background-color: white;
  position: fixed;
  z-index: -100;
}

#mask {
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 499;
  opacity: 0.8;
  overflow: hidden;
}

.blurBg {
  filter: blur(10px);
}

.icon-container {
  position: relative;
}

.icon {
  height: 1.6%;
  width: 1.6%;
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  right: 78%;
}

.side-icon {
  width: 40px !important;
  font-size: 25px;
  height: 40px;
}

.icon-img {
  width: 100%;
  text-align: center;
  padding: 2px;
}

.side-icon:hover {
  cursor: pointer;
}

.message-badge {
  position: absolute;
  color: black;
  background-color: rgba(210, 10, 40, 1);
  top: -7px;
  right: -10px;
  font-size: 10px;
  font-weight: bold;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  display: flex;
}

.in-plan {
  color: white;
  background: black;
}

.in-home {
  color: black;
  background-color: white;
}

.selected {
  color: white !important;
  background-color: rgba(210, 10, 40, 1);
}

.background-1 {
  background-image: url("~@/assets/images/backgroundimg.jpg");
}

.background-2 {
  background-image: url("~@/assets/images/backgroundMenu.png");
  width: 100vw;
}
.background-3 {
  background-image: url("~@/assets/images/background_login.jpg");
  background-position: left center;
  background-size: cover;
}

.inbox-container {
  max-height: 80vh;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;
}

.side-button-container {
  height: 15%;
  width: 40px;
  position: fixed;
  left: 0;
  top: 40%;
  z-index: 120;
}

@media screen and (min-width: 1920px) {
  .side-button-container {
    width: 40px;
  }
}
</style>
