<template>
  <div>
    <div :class="['container',state]" id="profile">
      <button type="button" class="close" aria-label="Close" @click="closeProfile()">
        <span aria-hidden="true" class="pull-right close-btn">
          <img src="@/assets/Icons/Icons/Icon-Close.svg" width="14" height="14" />
        </span>
      </button>
      <div class="content">
        <div class="content-header">
          <p>{{ $t('views.profile_title') }}</p>
          <span class="main-title-container">
            <p class="main-title">{{ user.first_name }} {{ user.last_name }}</p>
          </span>
          <p class="name">{{ user.company }}</p>
        </div>

        <!-- <div class="content-body">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipimod tempor incididunt ut labore et dolore magna aliqua.<br /> <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum.<br /> <br />
          Dolor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet.
        </p>
        </div>-->
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="anonymous-checkbox"
            v-model="anonymous"
            @click="toggleAnonymous()"
          />
          <label class="form-check-label white-text checkbox-text" for="anonymous-checkbox">
            <span class="logout__anonymous">Anonymer Modus Ein/Aus</span>
            <br />
            <span class="logout__anonymous--full">{{ $t('views.anonymous') }}</span>
          </label>
        </div>

        <div class="content-footer">
          <button class="logout" @click="logout">
            <span>{{ $t('views.buttons.logout') }}</span>
          </button>

          <p>
            Falls Sie eine manuelle Löschung Ihrer gesamten Daten erwünschen,
            <br />
            wenden Sie sich bitte an {{ $t('views.help_mail') }}
          </p>
          <!-- Account de-activation removed for HHW event
          <button class="delete-account" @click="deleteAccount">
            <span>{{ $t('views.buttons.delete_account') }}</span>
            <i class="fas fa-times-circle pull-right"></i>
          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  props: {
    state: String,
    closeProfile: Function,
    newUser: Object,
    user: Object
  },
  data() {
    return {};
  },
  computed: {
    anonymous: {
      get: function() {
        return this.$store.state.user.anonymous;
      },
      set: function(newValue) {
        this.toggleAnonymous(newValue);
      }
    }
  },

  methods: {
    async toggleAnonymous(anonymous) {
      try {
        await this.$http.patch('/anonymous', { anonymous: anonymous });
      } catch (e) {
        return (anonymous = !anonymous);
      }
    },
    logout() {
      if (window.websocket) {
        window.websocket.close();
        window.websocket = null;
      }
      this.$store.dispatch('authLogout').then(() => {
        this.$router.push('/login');
      });
    },
    deleteAccount() {
      this.$emit('delete-account');
    }
  }
};
</script>

<style lang="scss" scoped>
.closed {
  left: 100%;
}

.open {
  left: 71.5%;
}

.container {
  margin-top: 0px;
  width: 28.5%;
  height: 100%;
  position: fixed;
  background-color: $haefeleGrey;
  opacity: 0.9;
  overflow: auto;
  scrollbar-color: rgba(210, 10, 40, 1) rgba(140, 35, 50, 1);
  scrollbar-width: thin;
  transition: 0.3s;
}

p {
  font-size: 12px;
  font-weight: 400;
}

.main-title {
  color: $secondaryColor;
  font-size: 30px !important;
  font-weight: 300;
  width: 40%;
  margin: 5% 0% !important;
}

.logout {
  border: none;
  font-size: 12px;
  padding: 0 !important;

  &__anonymous {
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 1.6em;

    &--full {
      font-size: 10px !important;
      font-weight: 300;
    }
  }
}

.logout:hover {
  background-color: $secondaryColor;
}

.pull-right:hover {
  cursor: pointer;
}

.content {
  width: 63.5%;
  height: 60%;
  margin: 15% auto;
  color: #ffffff;
}

.content-header {
  margin-bottom: 5rem;
}
.content-header p {
  font-size: 16px;
  margin: 0;
}

.content-body {
  margin-top: 15%;
  margin-bottom: 20%;
}

.content-body p {
  font-size: 16px;
}

.content-footer button {
  display: block;
  width: 100%;
  height: 37px;
  margin: 8% 0%;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.content-footer button span {
  padding: auto;
  padding-left: 27.5px !important;
}

.checkbox-text {
  font-size: 12px;
}

.close {
  margin-top: 28px !important;
  margin-right: 18px !important;
  color: white;
  font-size: 15px;
  color: white;
  opacity: 1;
  font-weight: 100;
}

.delete-account {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(92, 90, 90);
  color: rgb(92, 90, 90);
  padding-left: 0px !important;
  padding-right: 9px !important;
}

.delete-account i {
  margin-top: 1%;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .container {
    width: 50%;
  }

  .open {
    left: 50%;
  }

  .content {
    position: fixed;
    width: auto;
    height: 60%;
  }

  .content div {
    margin: 5%;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .container {
    width: 40%;
  }

  .open {
    left: 60%;
  }

  .content-body {
    margin-bottom: 20%;
  }

  .content-header p {
    font-size: 16px;
  }

  p {
    font-size: 10px;
  }

  h4 {
    font-size: 18px;
  }
}

@media screen and (max-device-width: 768px) {
  .container {
    width: 40%;
  }

  .open {
    left: 60%;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
  }

  .open {
    left: 0%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1280px) {
  .content-footer button span {
    padding: auto;
    padding-left: 20px !important;
  }
}
</style>
